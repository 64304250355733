/* eslint-disable import/no-extraneous-dependencies */
/**
 * Modules dependencies
 */
require('regenerator-runtime/runtime');
const restClient = require('frontend-restclient');
const { restClients: { services } } = require('../config');
const { getMobileOsParam, getMobileOsVersionParam } = require('../utils/utils');


const servicesClient = restClient(services);
const endpointProblems = '/problems';

/**
 * Service interface
 */
class ProblemsService {
  static async getProblemsForEntities(data, osName, osVersion, isDraft, context) {
    const url = `${endpointProblems}?${getMobileOsParam(osName)}&${getMobileOsVersionParam(osVersion)}`;
    const response = await servicesClient.post(url, { data: { ...data, draft: isDraft }, context });
    return response;
  }
}

/**
 * Expose Service
 */

module.exports = ProblemsService;
