/* eslint-disable react/no-unknown-property */
const React = require('react');
const PropTypes = require('prop-types');

const Icon = ({ type, className, color }) => {
  switch (type) {
    case 'arrow-top':
      return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <path
            fill={color || '#3483FA'}
            fillRule="evenodd"
            d="M10.004 8.443L14.8 13.24l.849-.849-5.645-5.644-5.649 5.65.849.848z"
          />
        </svg>
      );
    case 'arrow-bottom':
      return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <path
            fill={color || '#3483FA'}
            fillRule="evenodd"
            d="M9.998 11.552L5.202 6.756l-.849.849 5.645 5.644 5.649-5.65-.849-.848z"
          />
        </svg>
      );
    default:
      return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="16" height="17" viewBox="0 0 16 17">
          <defs>
            <path id="a" d="M4.644 4.997L8.24 1.4 7.39.552 2.946 4.997l4.45 4.448.848-.848z" />
          </defs>
          <use fill={color || '#000'} fillOpacity={color ? '1' : '.25'} fillRule="evenodd" transform="translate(3 4)" xlinkHref="#a" />
        </svg>
      );
  }
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  color: '',
};

module.exports = Icon;
