/* eslint-disable react/forbid-prop-types */
const React = require('react');
const PropTypes = require('prop-types');
const { ListItem } = require('@andes/list');
const { CxWidget } = require('../../adapters/cxWidget');

const { useRef } = React;

const ContentButton = ({ content, sourceId, isDisabled, setIsDisabled, chevron, tracks = [] }) => {
  const contentButtonRef = useRef(null);
  const listItemProps = {};
  let closeWidgetLabel = 'Cerrar';
  if (typeof window !== 'undefined') {
    const pageLang = document?.documentElement?.lang;
    closeWidgetLabel = 'Close';
    if (pageLang.includes('pt')) {
      closeWidgetLabel = 'Fechar';
    }
  }

  if (content.deeplink) {
    listItemProps.onClick = () => {
      setIsDisabled(true);
      window.location.href = content.deeplink;
    };
  } else if (content.redirect_url) {
    listItemProps.onClick = () => {
      setIsDisabled(true);
      window.location.href = content.redirect_url;
    };
  } else {
    listItemProps.onClick = () => {
      setIsDisabled(true);
      CxWidget(content.direct_access, { closeWidgetLabel }, contentButtonRef.current);
    };
  }

  listItemProps.disabled = isDisabled;
  const sendTrack = () => {
    if (window && window.melidata) {
      window.melidata('cleanAndSend', 'event', {
        path: '/support/helpcard/action',
        event_data: {
          portal_source_id: parseInt(sourceId, 10),
          helpcard_contents: [content],
          helpcard_tracks: tracks,
        },
      });
    }
  };

  return (<ListItem
    {...listItemProps}
    onClick={() => {
      sendTrack();
      listItemProps.onClick();
    }}
    title={content.title}
    heightRow="small"
    data-testid={content.title}
    chevron={chevron}
    ref={contentButtonRef}
  />);
};

ContentButton.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    deepLink: PropTypes.string,
    redirect_url: PropTypes.string,
    direct_access: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  sourceId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  setIsDisabled: PropTypes.func,
  chevron: PropTypes.bool,
  deeplink: PropTypes.string,
  tracks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    track_data: PropTypes.objectOf(PropTypes.any),
  })),
};

ContentButton.defaultProps = {
  isNativeMobile: false,
  isDisabled: false,
  setIsDisabled: null,
  chevron: false,
  deeplink: null,
  tracks: [],
};

module.exports = ContentButton;
