const domains = require('frontend-platform_detection/config/domains.json');

const getSiteUrl = (platform = {}, env) => {
  let validDomains = domains.filter((d) => d.domain !== 'adminml.com');
  const { location } = document;
  let domainFromPlatform;
  if (platform) {
    domainFromPlatform = domains.find(d => d.siteId === platform.siteId
      && d.id === platform.id
      && (d.siteId === 'CBT' || (d.siteId !== 'CBT' && !d.domain.endsWith('.com'))));
  }
  if (location.href.includes('global')) {
    validDomains = validDomains.filter((d) => d.domain.includes('global'));
  }

  /**
   * Because MP does have a ".com" domain (www.mercadopago.com) we need first to restrict
   * this search to exclude ".com" domains, in case we couldn't find any we include them to make
   * CBT domins work.
   */
  const domainSearchForMP = domains.find(({ domain: d }) => location.href.includes(d) && !d.endsWith('.com'));
  const { domain, siteId } = domainFromPlatform || domainSearchForMP
  || validDomains.find(({ domain: d }) => location.href.includes(d)) || {};

  if (siteId === 'CBT') {
    // If the site is CBT, use the global-selling subdomain (since we have frontend rules for accepting
    // it as widget subdomian)
    // and add the '/cwdiget' at the end so it will redirect to the widget pool instead of global-selling
    return `https://${domain}/cxwidget`;
  }
  let urlEnv = 'prod';
  if (env && ['stage', 'dev'].includes(env)) {
    urlEnv = env;
  }
  return `https://${urlEnv}-cxwidget.${domain}`;
};

module.exports = getSiteUrl;
