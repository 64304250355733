/* istanbul ignore file */
const { NODE_ENV } = process.env;
const isProd = NODE_ENV === 'production';
const baseURLInternal = 'https://cx-support-service.melioffice.com/cx/support/service';
const baseURLProduction = 'http://cx-support-service.melisystems.com/cx/support/service';

module.exports = {
  restClients: {
    services: {
      baseURL: isProd ? baseURLProduction : baseURLInternal,
      timeout: isProd ? 1500 : 15000,
    },
  },
};
