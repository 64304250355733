/* eslint-disable camelcase */
const { buildDeepLink } = require('../utils/links');
const ProblemsService = require('../services/problems');

const contentRedirectRegex = /\/content\/([0-9]+)/;

const buildItemsFromContents = (contents, entity, config, device, platformId) => (
  contents.map((content) => {
    let contentId = content.content_id;
    if (content.type === 'REDIRECT' && !contentRedirectRegex.test(content.redirect_url)) {
      return {
        title: content.title,
        redirect_url: content.redirect_url,
      };
    } if (contentRedirectRegex.test(content.redirect_url)) {
      contentId = parseInt(content.redirect_url.match(contentRedirectRegex)[1], 10);
    }

    const isNativeMobile = device && device.webView;
    // eslint-disable-next-line camelcase
    const direct_access = {
      title: content.title,
      source_id: config.source_id,
      case_data: config.case_data,
      portal_access: config.portal_access,
      direct_access: {
        placeholders: entity.problem.placeholders,
        content_id: contentId,
      },
    };

    return isNativeMobile ? {
      title: content.title,
      deeplink: buildDeepLink(direct_access, platformId),
    } : {
      title: content.title,
      direct_access,
    };
  })
);

const getHelpCardData = async (config, device, platform, context) => {
  if (!device) {
    throw new Error('Param device is required');
  }

  if (!platform) {
    throw new Error('Param platform is required');
  }

  const { title, expandable, source_id: sourceId, user_id: userId, case_data: caseData, entities = [], is_draft: isDraft, clickDelay } = config;

  const data = {
    source_id: sourceId,
    user_id: userId || -1,
    portal: platform.id,
    case_data: caseData,
    entities: entities.map(entity => ({
      ...entity,
      problem: { ...entity.problem, site_id: platform.siteId },
    })),
    attrs: ['title', 'redirect_url', 'type'],
  };

  const osName = device.nativeApp ? device.nativeApp.os.toLowerCase() : '';
  const osVersion = device.nativeApp ? device.nativeApp.version : '';

  const response = await ProblemsService.getProblemsForEntities(data, osName, osVersion, isDraft, context);
  const contents = response.data.reduce((acc, entityResponse) => {
    const currEntity = data.entities.find(entity => entity.entity.name === entityResponse.entity);
    return acc.concat(buildItemsFromContents(entityResponse.contents, currEntity, config, device, platform.id));
  }, []);
  const componentConfig = {
    title,
    expandable,
    sourceId,
    contents,
    clickDelay,
  };

  return componentConfig;
};

module.exports = getHelpCardData;
