const React = require('react');
const { string, node, oneOf } = require('prop-types');
const Icon = require('../Icon');

const { useState, useEffect } = React;
const ENTER_KEYCODE = 13;

const Expandable = ({ collapsedText, expandedText, children, buttonPosition }) => {
  const [expanded, setExpanded] = useState(false);
  const childrenRef = React.createRef();
  const [height, setHeight] = useState(0);
  const [buttonClass, setClass] = useState(null);

  const getChildrenLeftHeight = () => {
    const childrenLeftNodes = childrenRef.current.childNodes;
    let childrenHeight = 0;

    childrenLeftNodes.forEach((child) => {
      childrenHeight += child.offsetHeight;
      const style = getComputedStyle(child, null);
      if (style.marginTop) {
        childrenHeight += parseInt(style.marginTop, 10);
      }
      if (style.marginBottom) {
        childrenHeight += parseInt(style.marginBottom, 10);
      }
    });

    return childrenHeight;
  };

  const styles = {
    height: `${height}px`,
  };

  const handleClickEvent = (e) => {
    e.preventDefault();
    setClass('fade-out');
    setExpanded(!expanded);
  };

  const handleOnKeyUp = (e, fn) => {
    if (e.keyCode === ENTER_KEYCODE) {
      fn(e);
    }
  };

  useEffect(() => {
    setHeight(expanded ? getChildrenLeftHeight() : 0);
    const timer = setTimeout(() => {
      if (buttonClass) {
        setClass('fade-in');
      }
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const showMoreButton = () => (
    <div
      id="button"
      className={`daisy-expandable__button ${buttonClass}`}
      data-js="daisy-expandable__button"
      onClick={handleClickEvent}
      onKeyUp={(e) => {
        handleOnKeyUp(e, handleClickEvent);
      }}
      role="button"
      tabIndex={0}
    >
      <span className="daisy-expandable__button-text">{expanded ? expandedText : collapsedText}</span>
      <Icon className="daisy-expandable__button-icon" type={expanded ? 'arrow-top' : 'arrow-bottom'} />
    </div>
  );

  return (
    <div className={`daisy-expandable daisy-expandable--${buttonPosition}`} data-js="expandable">
      {showMoreButton()}
      <div className="daisy-expandable__content" style={styles} ref={childrenRef} key="expandable-content">
        { children }
      </div>
    </div>
  );
};

Expandable.propTypes = {
  buttonPosition: oneOf(['top', 'bottom']),
  collapsedText: string.isRequired,
  expandedText: string.isRequired,
  children: node.isRequired,
};

Expandable.defaultProps = {
  buttonPosition: 'bottom',
};

module.exports = Expandable;
