/* eslint-disable react/forbid-prop-types */
/* eslint-disable nordic/no-style-tags */
/* eslint-disable nordic/no-script-tags */
/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const Card = require('@andes/card');
const { useEffect, useState, useRef } = require('react');
const { CardHeader } = require('@andes/card');
const ExpandableList = require('../ExpandableList');
const ContentButton = require('../ContentButton');

const useIsInViewport = (ref) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  let observer = null;
  const callback = ([entry]) => {
    setIsIntersecting(entry.isIntersecting);
  };
  if (typeof window !== 'undefined') {
    observer = new IntersectionObserver(callback, {
      threshold: 0.8,
      root: null,
      rootMargin: '0px',
    });
  }

  useEffect(() => {
    if (observer) observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};


/**
 * HelpCard
 */
const HelpCard = ({ title, sourceId, contents, expandable, clickDelay = 0, chevron, tracks = [] }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const contentRef = useRef(null);
  const isVisible = useIsInViewport(contentRef);
  const [viewTracked, setViewTracked] = useState(false);

  const cleanedTracks = tracks.map(track => ({
    name: track?.name,
    value: track?.value,
    track_data: track?.track_data,
  }));

  useEffect(() => {
    if (!isDisabled) {
      return;
    }

    setTimeout(() => {
      setIsDisabled(false);
    }, clickDelay);
  }, [isDisabled]);

  useEffect(() => {
    if (window && window.melidata) {
      window.melidata('cleanAndSend', 'view', {
        path: '/support/helpcard/contents',
        event_data: {
          portal_source_id: parseInt(sourceId, 10),
          helpcard_contents: contents,
          helpcard_tracks: cleanedTracks,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // listen for changes
    if (isVisible && !viewTracked) {
      setViewTracked(true);
      if (window && window.melidata) {
        window.melidata('cleanAndSend', 'view', {
          path: '/support/helpcard/view_component',
          event_data: {
            portal_source_id: parseInt(sourceId, 10),
            helpcard_contents: contents,
            helpcard_tracks: cleanedTracks,
          },
        });
      }
    }
  }, [isVisible]);

  return contents.length
    ? (
      <div ref={contentRef}>
        <Card borderHeader paddingSize={16}>
          <CardHeader>
            <h1>{title}</h1>
          </CardHeader>
          <ExpandableList {...expandable}>
            {
            contents.map(content => (<ContentButton
              content={content}
              key={content.title}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
              chevron={chevron}
              tracks={cleanedTracks}
              sourceId={sourceId}
            />))
            }
          </ExpandableList>
        </Card>
      </div>
    )
    : null;
};

/**
 * HelpCard propTypes
 */
HelpCard.propTypes = {
  title: PropTypes.string.isRequired,
  sourceId: PropTypes.number.isRequired,
  contents: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    deepLink: PropTypes.string,
    redirect_url: PropTypes.string,
    direct_access: PropTypes.objectOf(PropTypes.any),
  })).isRequired,
  expandable: PropTypes.shape({
    visibleRows: PropTypes.number,
    collapsedText: PropTypes.string,
    expandedText: PropTypes.string,
  }),
  clickDelay: PropTypes.number,
  chevron: PropTypes.bool,
  tracks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    track_data: PropTypes.objectOf(PropTypes.any),
  })),
};

HelpCard.defaultProps = {
  expandable: {
    visibleRows: 6,
    collapsedText: '',
    expandedText: '',
  },
  clickDelay: 0,
  chevron: false,
  tracks: [],
};

/**
 * Expose HelpCard
 */
module.exports = HelpCard;

/**
 * Expose for testing
 */
/* istanbul ignore next */
if (process.env.NODE_ENV === 'test') {
  exports = module.exports;
  // exports.test = test;
}
