const React = require('react');
const { string, node, number } = require('prop-types');
const { List } = require('@andes/list');
const Expandable = require('../Expandable');

const ExpandableList = ({
  title,
  children,
  visibleRows,
  collapsedText,
  expandedText,
}) => {
  const childrenShown = children.slice(0, visibleRows);
  const childrenHidden = children.slice(visibleRows, children.length);

  return (
    <List title={title} srLabel="content-list" withDividers>
      {childrenShown}
      {childrenHidden.length > 1 ? (
        <Expandable collapsedText={collapsedText} expandedText={expandedText}>
          {childrenHidden}
        </Expandable>
      ) : (
        childrenHidden
      )}
    </List>
  );
};

ExpandableList.propTypes = {
  children: node.isRequired,
  title: string,
  visibleRows: number,
  collapsedText: string.isRequired,
  expandedText: string.isRequired,
};

ExpandableList.defaultProps = {
  title: null,
  visibleRows: 6,
};

module.exports = ExpandableList;
