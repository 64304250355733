const objectToParamsString = (item) => {
  const params = Object.entries(item).map(([key, value]) => {
    if (['number', 'string'].includes(typeof value)) {
      return `${key}=${encodeURIComponent(value)}`;
    }
    return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
  });
  return params.join('&');
};

const buildDeepLink = (item, platformId) => {
  const businessPrefix = platformId === 'MP' ? 'mercadopago' : 'meli';

  return `${businessPrefix}://cx/support/yoshi?${objectToParamsString(item)}`;
};

module.exports = {
  objectToParamsString,
  buildDeepLink,
};
