const React = require('react');
const PropTypes = require('prop-types');
const { Module } = require('frontend-remote-modules');
const moduleUrls = require('./module-urls.json');

const HelpButton = ({ render, sourceId, flow, siteId, platformId, defaultComponent }) => {
  const moduleUrl = moduleUrls[`${siteId}`][`${platformId}`];

  return (
    <Module
      host={moduleUrl}
      name="chat_button"
      sourceId={sourceId}
      flow={flow}
      render={render}
      errorComponent={defaultComponent}
    />
  );
};

HelpButton.propTypes = {
  defaultComponent: PropTypes.node,
  render: PropTypes.func,
  sourceId: PropTypes.string,
  flow: PropTypes.string,
  siteId: PropTypes.string,
  platformId: PropTypes.string,
};

HelpButton.defaultProps = {
  flow: 'AA_ML',
};

module.exports = HelpButton;
