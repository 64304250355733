/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* istanbul ignore file */
const getSiteUrl = require('../utils/url');

/**
     * UTILS - Debounce function.
     * Returns a function, that, as long as it continues to be invoked, will not
     * be triggered. The function will be called after it stops being called for
     * N milliseconds. If `immediate` is passed, trigger the function on the
     * leading edge, instead of the trailing.
     */
const debounce = (func, wait, immediate) => {
  let timeout;
  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
const buildQueryParams = (context) => {
  const response = Object.keys(context).map((key) => {
    const value = context[key];
    if (['number', 'string'].includes(typeof value)) {
      return `${key}=${value}`;
    }
    return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
  });
  return response.join('&');
};

const buildURL = (context = {}) => {
  const { platform, test_env: env } = context;
  const baseUrl = getSiteUrl(platform, env);

  if (!Object.keys(context)) return `${baseUrl}/error`;

  const path = context.direct_access ? '/content/direct' : '/content/entity/problems';
  const params = buildQueryParams(context);

  return `${baseUrl}${path}?${params}`;
};

const loadingControl = (action) => {
  const loading = document.querySelector('.cx-loading');
  switch (action) {
    case 'show':
      loading.classList.remove('cx-loading--hidden');
      break;
    case 'hide':
      loading.classList.add('cx-loading--hidden');
      break;
    default:
      break;
  }
};

const widgetControl = (action, element = null) => {
  const widget = document.querySelector('.cx-widget');
  widget.classList.remove('cx-widget--fake-destroy');
  switch (action) {
    case 'show':
      widget.classList.add('cx-widget--visible');
      break;
    case 'close':
      if (widget.classList.contains('cx-widget--collapsed')) {
        widget.classList.add('cx-widget--fake-destroy');
        setTimeout(() => {
          widget.classList.remove('cx-widget--collapsed');
          widget.classList.remove('cx-widget--visible');
          widget.style.transform = '';
        }, 100);
      } else {
        widget.classList.remove('cx-widget--visible');
        widget.style.transform = '';
      }
      if (element) {
        element.focus();
      }
      break;
    case 'minimize':
      widget.classList.add('cx-widget--collapsed');
      widget.style.transform = 'translateZ(0) translate3d(0, 0, 0) translateX(-360px) translateY(calc(100% - 60px))';
      break;
    case 'maximize':
      widget.classList.remove('cx-widget--collapsed');
      widget.style.transform = '';
      break;
    case 'focus':
      widget.focus();
      break;
    default:
      break;
  }
};

const calculateHeight = debounce(() => {
  const widgetNode = document.querySelector('.cx-widget');
  const header = document.querySelector('.nav-header');
  let footer = document.querySelector('.nav-footer');
  let pixelsOfFooterInScreen;
  let pixelsOfHeaderInScreen;
  if (header) {
    pixelsOfHeaderInScreen = header.offsetHeight - window.scrollY;
    pixelsOfHeaderInScreen = pixelsOfHeaderInScreen > header.offsetHeight ? header.offsetHeight : pixelsOfHeaderInScreen;
    widgetNode.style.top = pixelsOfHeaderInScreen > 0 ? `${pixelsOfHeaderInScreen}px` : '0';
  } else {
    widgetNode.style.top = '0';
  }
  if (!footer) footer = document.querySelector('.nav-footer');
  const isMinimized = document.querySelector('.cx-widget--collapsed');
  if (footer && !isMinimized) {
    pixelsOfFooterInScreen = footer.offsetHeight - (footer.getBoundingClientRect().bottom - document.documentElement.clientHeight);
    pixelsOfFooterInScreen = pixelsOfFooterInScreen > 0 ? pixelsOfFooterInScreen : 0;
    widgetNode.style.bottom = pixelsOfFooterInScreen > 0 ? `${pixelsOfFooterInScreen}px` : '0';
  } else {
    widgetNode.style.bottom = '0';
  }
  let height = window.innerHeight;
  if (footer && pixelsOfFooterInScreen > 0) {
    height -= pixelsOfFooterInScreen;
  }
  if (header && pixelsOfHeaderInScreen > 0) {
    height -= pixelsOfHeaderInScreen;
  }
  widgetNode.style.height = `${height}px`;
}, 100);

const createFormChilds = (context = {}) => Object.keys(context).reduce((acc, key) => {
  const inputValue = typeof context[key] === 'object'
    ? (typeof String.prototype.replaceAll !== 'undefined' ? JSON.stringify(context[key]).replaceAll('\'', '&#39;') : JSON.stringify(context[key]))
    : context[key];
  const input = `<input name="${key}" type="hidden" value='${inputValue}' />`;
  return `${acc}${input}`;
}, '');

function injectIframe(context = {}, options, element) {
  const method = (options && options.method) || 'GET';
  const forcePost = method.toUpperCase() === 'POST';

  const title = context ? context.title : '';
  const path = context.direct_access ? '/content/direct' : '/content/entity/problems';
  const url = forcePost ? `${getSiteUrl(context.platform, context.test_env)}${path}` : buildURL(context);
  const formAction = `${getSiteUrl(context.platform, context.test_env)}${path}`;
  const widgetIframe = window.document.getElementById('cx-widget-iframe');

  if (widgetIframe) {
    document.querySelectorAll('.cx-widget__title')[0].innerHTML = title;

    if (forcePost) {
      const form = document.querySelector('#cx-widget-form');
      form.action = formAction;
      form.innerHTML = createFormChilds(context);
      form.submit();
    } else {
      widgetIframe.contentWindow.location.replace(url);
    }
    return;
  }

  const wrapper = `
        <div class="cx-widget" tabindex="-1" data-testid="cx-widget">
        <form target="cx-widget-iframe" id="cx-widget-form" method="POST" action="${formAction}">${createFormChilds(context)}</form>
          <header>
            <div class="cx-widget__protection-band"></div>
            <p id="maximizeBtn" class="cx-widget__title-container">
              <span class="cx-widget__title">${title}</span>
            </p>
            <div class="cx-widget__actions">
              <div id="minimizeBtn" class="button-minimize" role="button" aria-label="minimize" tabindex="0"></div>
              <div id="closeBtn" data-message="${options?.closeWidgetLabel || 'Cerrar'}" role="button" aria-label="close" tabindex="0" class="button-close"></div>
            </div>
          </header>
          <section class="cx-widget__container">
            <iframe id="cx-widget-iframe" class="cx-widget-iframe" name="cx-widget-iframe" data-hj-allow-iframe src="${url}"></iframe>
            <div class="cx-loading cx-loading--block cx-loading--hidden">
              <div class="cx-loading__container">
                <svg class="cx-loading__spinner cx-loading__spinner--large" viewBox="25 25 50 50">
                  <circle class="cx-loading__spinner-path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
              </div>
              <div class="cx-loading__mask"></div>
          </div>
          </section>
        </div>`;
  document.body.insertAdjacentHTML('beforeend', wrapper);
  const widgetForm = document.querySelector('#cx-widget-form');
  if (forcePost) widgetForm.submit();

  calculateHeight();

  window.addEventListener('resize', calculateHeight);
  window.addEventListener('scroll', calculateHeight);
  window.addEventListener(
    'message',
    (event) => {
      const minimizeButton = document.querySelector('.button-minimize');
      const closeButton = document.querySelector('.button-close');

      switch (event.data) {
        case 'blueIcons':
          minimizeButton.classList.remove('button-minimize--white');
          closeButton.classList.remove('button-close--white');
          break;
        case 'lightIcons':
          minimizeButton.classList.add('button-minimize--white');
          closeButton.classList.add('button-close--white');
          break;
        case 'closeWidget':
          widgetControl('close', element);
          break;
        case 'hideLoading':
          loadingControl('hide');
          break;
        case 'showLoading':
        case 'goingBack':
          loadingControl('show');
          break;
        default:
          break;
      }
    },
    false,
  );
  const maximizeBtn = document.getElementById('maximizeBtn');
  const minimizeBtn = document.getElementById('minimizeBtn');
  const closeBtn = document.getElementById('closeBtn');

  maximizeBtn.addEventListener('click', (e) => {
    e.stopPropagation();
    widgetControl('maximize');
  });

  minimizeBtn.addEventListener('click', (e) => {
    e.stopPropagation();
    widgetControl('minimize');
    calculateHeight();
  });

  closeBtn.addEventListener('click', (e) => {
    e.stopPropagation();
    widgetControl('close', element);
  });

  closeBtn.addEventListener('keydown', (e) => {
    e.stopPropagation();
    if (e.keyCode) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        e.preventDefault();
        widgetControl('close', element);
      }
    }
  });
}
const openWidget = (context, options, element) => {
  if (typeof window !== 'undefined') {
    injectIframe(context, options, element);
    setTimeout(() => {
      calculateHeight();
      widgetControl('maximize');
      widgetControl('show');
      widgetControl('focus');
      loadingControl('show');
    }, 330);
  }
};
/**
 * Opens a widget given a config.
 * For more information visit: https://meli.workplace.com/notes/ayuda-contextual/gu%C3%ADa-de-implementaci%C3%B3n-del-widget-de-ayuda/877897946363413
 * @param {object} context - The config object including all nescesary data to open a widget.
 * @param {object} options - (optional) Additional options such as method to use in request.
 * @param {object} element - (optional) An HTML element to focus back on close.
 */
module.exports.CxWidget = (context, options = null, element = null) => {
  openWidget(context, options, element);
};

